"use client";

import { AlertCircleIcon, CheckCircle } from "lucide-react";
import NoticeWrapper, { NoticeProps, NoticeVariant } from ".";
import { ReactNode } from "react";

type Props = { icon?: ReactNode } & Omit<NoticeProps, "children">;

const AlertBox = ({ variant, icon }: Props) => {
  return (
    <NoticeWrapper variant={variant}>
      {({ bgColor, color }) => {
        let notice_icon = icon ?? <AlertCircleIcon size={48} color={color} />;
        if (variant === NoticeVariant.Success) {
          notice_icon = <CheckCircle color={color} size={48} />;
        }
        return (
          <div
            style={{ backgroundColor: bgColor }}
            className='flex items-center justify-center rounded-full w-[68px] h-[68px]'
          >
            {notice_icon}
          </div>
        );
      }}
    </NoticeWrapper>
  );
};

export default AlertBox;
