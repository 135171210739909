export type RoleType = {
  id: string;
  name: string;
  permissions: string[];
};

export enum WholePermissions {
  //Global permissions
  VIEW_ADMIN_PANEL = "VIEW_ADMIN_PANEL",

  //Posts's permissions
  DELETE_POST = "DELETE_POST",
  EDIT_POST = "EDIT_POST",

  //Comments's permissions
  GET_COMMENTS = "GET_COMMENTS",
  CREATE_COMMENTS = "CREATE_COMMENTS",
  VIEW_COMMENT = "VIEW_COMMENT",
  EDIT_COMMENT = "EDIT_COMMENT",
  DELETE_COMMENT = "DELETE_COMMENT",

  //User's permissions
  GET_USERS = "GET_USERS",
  CREATE_USER = "CREATE_USER",
  VIEW_USER = "VIEW_USER",
  EDIT_USER = "EDIT_USER",
  DELETE_USER = "DELETE_USER",

  //Categories' permissions
  GET_CATEGORIES = "GET_CATEGORIES",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  VIEW_CATEGORY = "VIEW_CATEGORY",
  EDIT_CATEGORY = "EDIT_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",

  //Tags' permissions
  GET_TAGS = "GET_TAGS",
  CREATE_TAG = "CREATE_TAG",
  VIEW_TAG = "VIEW_TAG",
  EDIT_TAG = "EDIT_TAG",
  DELETE_TAG = "DELETE_TAG",

  //Product's permissions
  GET_PRODUCTS = "GET_PRODUCTS",
  GET_MY_PRODUCTS = "GET_MY_PRODUCTS",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  VIEW_PRODUCT = "VIEW_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  DELETE_PRODUCT = "DELETE_PRODUCT",

  //Store's permissions
  GET_STORES = "GET_STORES",
  GET_MY_STORES = "GET_MY_STORES",
  CREATE_STORE = "CREATE_STORE",
  VIEW_STORE = "VIEW_STORE",
  EDIT_STORE = "EDIT_STORE",
  DELETE_STORE = "DELETE_STORE",

  //Orders's permissions
  GET_ORDERS = "GET_ORDERS",
  GET_MY_ORDERS = "GET_MY_ORDERS",
  CREATE_ORDER = "CREATE_ORDER",
  VIEW_ORDER = "VIEW_ORDER",
  EDIT_ORDER = "EDIT_STORE",
  DELETE_ORDER = "DELETE_ORDER",

  //Roles's permissions
  GET_PERMISSIONS = "GET_PERMISSIONS",
  GET_ROLES = "GET_ROLES",
  CREATE_ROLE = "CREATE_ROLE",
  VIEW_ROLE = "VIEW_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  DELETE_ROLE = "DELETE_ROLE",

  //File's permissions
  DELETE_FILES = "DELETE_FILES",

  //Settings' permission
  ADD_SETTINGS = "ADD_SETTINGS",
  GET_SETTINGS = "GET_SETTINGS",
  VIEW_SETTINGS = "VIEW_SETTINGS",
  EDIT_SETTINGS = "EDIT_SETTINGS",
  DELETE_SETTNGS = "DELETE_SETTNGS",
}
