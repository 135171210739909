import { Navigate, createBrowserRouter } from "react-router-dom";
import PublicRoutes from "./public-wrapper";
import { lazy } from "react";
import PrivateRoutes from "./private-wrarpper";
import { paths } from "./paths";
import RootBoundary from "@/components/partials/error-boundry";

//Login page
const LoginPage = lazy(() => import("@/pages/login"));
const HomePage = lazy(() => import("@/pages/home"));

//Users page
const UsersListPage = lazy(() => import("@/pages/users/index"));
const UsersViewPage = lazy(() => import("@/pages/users/view"));
const UsersAddPage = lazy(() => import("@/pages/users/add"));

//Comments page
const CommentsListPage = lazy(() => import("@/pages/comments/index"));
const CommentsViewPage = lazy(() => import("@/pages/comments/view"));
const CommentsAddPage = lazy(() => import("@/pages/comments/add"));

//Products page
const ProductsListPage = lazy(() => import("@/pages/products/index"));
const ProductsViewPage = lazy(() => import("@/pages/products/view"));
const ProductsAddPage = lazy(() => import("@/pages/products/add"));

//Posts page
const PostsListPage = lazy(() => import("@/pages/posts/index"));
const PostsViewPage = lazy(() => import("@/pages/posts/view"));
const PostsAddPage = lazy(() => import("@/pages/posts/add"));

//Categories page
const CategoriesListPage = lazy(() => import("@/pages/categories/index"));
const CategoriesViewPage = lazy(() => import("@/pages/categories/view"));
const CategoriesAddPage = lazy(() => import("@/pages/categories/add"));

//Sliders page
const SlidersListPage = lazy(() => import("@/pages/sliders/index"));
const SlidersViewPage = lazy(() => import("@/pages/sliders/view"));
const SlidersAddPage = lazy(() => import("@/pages/sliders/add"));

//Collaboration page
const CollaborationListPage = lazy(
  () => import("@/pages/collaborations/index")
);
const CollaborationViewPage = lazy(() => import("@/pages/collaborations/view"));

//Roles page
const RolesListPage = lazy(() => import("@/pages/roles/index"));
const RoleViewPage = lazy(() => import("@/pages/roles/view"));
const RoleAddPage = lazy(() => import("@/pages/roles/add"));

//Settings page
const SettingsListPage = lazy(() => import("@/pages/settings/index"));
const SettingsViewPage = lazy(() => import("@/pages/settings/view"));
const SettingsAddPage = lazy(() => import("@/pages/settings/add"));

//NotFound Page
const NotFoundPage = lazy(() => import("@/pages/not-found"));

const router = createBrowserRouter([
  {
    element: <PrivateRoutes />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: paths.home,
        element: <Navigate to={paths.dashboard.index} />,
      },
      {
        path: paths.dashboard.index,
        element: <HomePage />,
      },
      {
        path: "users",
        children: [
          {
            path: "list",
            element: <UsersListPage />,
          },
          {
            path: "view/:id",
            element: <UsersViewPage />,
          },
          {
            path: "add",
            element: <UsersAddPage />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            path: "list",
            element: <ProductsListPage />,
          },
          {
            path: "view/:id",
            element: <ProductsViewPage />,
          },
          {
            path: "add",
            element: <ProductsAddPage />,
          },
        ],
      },
      {
        path: "comments",
        children: [
          {
            path: "list",
            element: <CommentsListPage />,
          },
          {
            path: "view/:id",
            element: <CommentsViewPage />,
          },
          {
            path: "add",
            element: <CommentsAddPage />,
          },
        ],
      },
      {
        path: "posts",
        children: [
          {
            path: "list",
            element: <PostsListPage />,
          },
          {
            path: "view/:id",
            element: <PostsViewPage />,
          },
          {
            path: "add",
            element: <PostsAddPage />,
          },
        ],
      },
      {
        path: "categories",
        children: [
          {
            path: "list",
            element: <CategoriesListPage />,
          },
          {
            path: "view/:id",
            element: <CategoriesViewPage />,
          },
          {
            path: "add",
            element: <CategoriesAddPage />,
          },
        ],
      },
      {
        path: "sliders",
        children: [
          {
            path: "list",
            element: <SlidersListPage />,
          },
          {
            path: "view/:id",
            element: <SlidersViewPage />,
          },
          {
            path: "add",
            element: <SlidersAddPage />,
          },
        ],
      },
      {
        path: "collaborations",
        children: [
          {
            path: "list",
            element: <CollaborationListPage />,
          },
          {
            path: "view/:id",
            element: <CollaborationViewPage />,
          },
        ],
      },
      {
        path: "roles",
        children: [
          {
            path: "list",
            element: <RolesListPage />,
          },
          {
            path: "view/:id",
            element: <RoleViewPage />,
          },
          {
            path: "add",
            element: <RoleAddPage />,
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "list",
            element: <SettingsListPage />,
          },
          {
            path: "view/:id",
            element: <SettingsViewPage />,
          },
          {
            path: "add",
            element: <SettingsAddPage />,
          },
        ],
      },
    ],
  },
  {
    path: paths.login.index,
    element: <PublicRoutes />,
    errorElement: <RootBoundary />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default router;
