"use client";

import { WarningIcon } from "@/components/icons";
import PromptBox from "@/components/ui/prompt-box";
import { useAppDispatch } from "@/store";
import { logout } from "@/store/slices/auth/slice";
import { ReactNode } from "react";
import colors from "tailwindcss/colors";
import { NoticeVariant } from "../notice";

type Props = {
  renderer: (open: () => void) => ReactNode;
};

export default function LogoutRenderer({ renderer }: Props) {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <PromptBox
      title='Logout'
      description='Are you sure to logout?'
      submitText='Logout'
      cancelText='Cancel'
      onSubmit={handleLogout}
      trigger={renderer}
      icon={<WarningIcon size={48} color={colors.yellow[400]} />}
      variant={NoticeVariant.Warning}
    />
  );
}
