import useAuth from "@/hooks/auth";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { paths } from "./paths";
import { useAppDispatch } from "@/store";
import { getProfileThunk } from "@/store/slices/auth/slice";
import Sidebar from "@/components/partials/sidebar";

export default function PrivateRoutes() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  const [initState, setInitState] = useState(false);

  const navigate = useNavigate();

  const { accessToken } = useAuth();
  useEffect(() => {
    if (!accessToken) {
      return navigate(paths.login.index);
    }
    setInitState(true);
  }, [accessToken, navigate]);

  if (initState === false) return null;

  return (
    <div className='flex'>
      <Sidebar />
      <div className='ml-64 w-full'>
        <main className='p-6 bg-gray-50 min-h-screen'>
          <Outlet />
        </main>
      </div>
    </div>
  );
}
