import { ReactNode } from "react";
import colors from "tailwindcss/colors";

export type NoticeProps = {
  variant: NoticeVariant;
  children: ({
    color,
    bgColor,
  }: {
    color: string;
    bgColor: string;
  }) => ReactNode;
};

export enum NoticeVariant {
  Success = 0,
  Warning = 1,
  Error = 2,
}

const NoticeWrapper = ({ variant, children }: NoticeProps) => {
  let color: string = colors.yellow[400];
  let bgColor: string = colors.yellow[700];

  switch (variant) {
    default:
    case NoticeVariant.Warning:
      color = colors.yellow[600];
      bgColor = colors.yellow[50];
      break;
    case NoticeVariant.Success:
      color = colors.green[600];
      bgColor = colors.green[100];
      break;
    case NoticeVariant.Error:
      color = colors.red[600];
      bgColor = colors.red[100];
      break;
  }

  return <>{children({ color, bgColor })}</>;
};

export default NoticeWrapper;
