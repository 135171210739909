// src/store/slices/authSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { UserType } from "@/types/user";
import axiosInstance, { FetchDataType } from "@/services/axios";
import { toast } from "sonner";
import { WholePermissions } from "@/types/role";
import axios from "axios";
import { VARZ } from "@/const/varz";

// Define the initial state using a TypeScript interface
interface AuthState {
  user: UserType | null;
  permissions: string[];
  accessToken: string | null;
  refreshToken: string | null;
  isLoading: boolean;
  error: string | null;
}

// Define the login response type
interface LoginResponse {
  tokens: { accessToken: string; refreshToken: string };
  user: UserType;
  permissions: string[];
}

// Initialize the initial state
const initialState: AuthState = {
  user: null,
  permissions: [],
  accessToken: null,
  refreshToken: null,
  isLoading: false,
  error: null,
};

export const refreshAccessToken = createAsyncThunk(
  "auth/refreshAccessToken",
  async (refreshToken: string, { rejectWithValue }) => {
    try {
      const response = await axios.post<
        FetchDataType<{ tokens: { accessToken: string; refreshToken: string } }>
      >(
        "/auth/refresh-token",
        {
          refreshToken,
        },
        {
          baseURL: VARZ.apiBaseUrl,
        }
      );
      const data = response.data.data;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for login
export const loginThunk = createAsyncThunk<
  LoginResponse,
  { email: string; password: string },
  { rejectValue: string }
>("auth/login/password", async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/auth/password", {
      ...credentials,
    });
    toast.success("Logged in successfully.");
    return response.data.data;
  } catch (error) {
    return rejectWithValue("");
  }
});

// Async thunk for update information
export const updateProfileThunk = createAsyncThunk<
  any,
  { name?: string; admin_name?: string; phone?: string; address?: string },
  { rejectValue: string }
>("auth/update-profile", async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch("/users/me", payload);

    toast.success("Profile has been updated");
    return response.data;
  } catch (error) {
    return rejectWithValue("");
  }
});

// Async thunk for get user information
export const getProfileThunk = createAsyncThunk<
  any,
  undefined,
  { rejectValue: string }
>("auth/get-profile", async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/users/me");
    return response.data;
  } catch (error) {
    return rejectWithValue("");
  }
});

// Define the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        const { accessToken, refreshToken } = action.payload.tokens;

        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
      })
      .addCase(refreshAccessToken.rejected, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        state.user = null;
      })
      // Login actions
      .addCase(loginThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        loginThunk.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.user = action.payload.user;
          state.accessToken = action.payload.tokens.accessToken;
          state.refreshToken = action.payload.tokens.refreshToken;
          state.isLoading = false;
        }
      )
      .addCase(loginThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to login";
      });

    builder
      .addCase(updateProfileThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateProfileThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.user = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(updateProfileThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to update profile";
      });

    builder
      .addCase(getProfileThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getProfileThunk.fulfilled,
        (state, action: PayloadAction<{ data: UserType }>) => {
          const user = action.payload.data;

          state.user = user;

          let perms: string[] = [];
          for (let role of user.roles) {
            perms = [...new Set([...perms, ...role.permissions])];
          }

          state.permissions =
            typeof perms?.[0] === "object" && !Array.isArray(perms?.[0])
              ? Object.keys(perms[0])
              : perms;

          state.isLoading = false;
        }
      )
      .addCase(getProfileThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

// Export the actions
export const { logout } = authSlice.actions;

// Export the selector for accessing the auth state
export const selectAuth = (state: RootState) => state.auth;

// Export the reducer
export default authSlice.reducer;
