import ODialog, { ODialogProps } from "@/components/shared-ui/o-dialog";
import { ReactNode } from "react";
import { OrgButton } from "@/components/shared-ui";
import { NoticeVariant } from "@/components/shared/notice";
import AlertBox from "@/components/shared/notice/alert-box";

type Props = {
  onSubmit?: () => void;
  submitText?: string;
  cancelText?: string;
  title: string;
  description: string;
  loading?: boolean;
} & (
  | { icon?: undefined; variant: never }
  | { icon: ReactNode; variant: NoticeVariant }
) &
  ODialogProps;

const PromptBox = ({
  icon,
  submitText = "Accept",
  cancelText = "Cancel",
  onSubmit,
  title,
  description,
  trigger,
  variant,
  loading = false,
  ...rest
}: Omit<Props, "children">) => {
  return (
    <ODialog trigger={trigger} {...rest}>
      {(close) => {
        let alert_node = null;
        if (icon && variant) {
          alert_node = <AlertBox variant={variant} icon={icon} />;
        }
        return (
          <div className='w-full flex min-h-[200px] pt-6 justify-between flex-col items-center gap-y-4'>
            <div className='flex flex-col gap-y-4 items-center'>
              {alert_node}
              <strong className='text-neutral-on-background'>{title}</strong>
              <p className='text-neutral-on-background'>{description}</p>
            </div>
            <div className='flex items-center w-full gap-x-4'>
              <OrgButton loading={loading} onClick={onSubmit}>
                {submitText}
              </OrgButton>
              <OrgButton variant={"outline"} onClick={close}>
                {cancelText}
              </OrgButton>
            </div>
          </div>
        );
      }}
    </ODialog>
  );
};

export default PromptBox;
