import { BreadcrumbProps } from "@/components/shared-ui/o-breadcrumb";
import { VARZ } from "@/const/varz";
import { OrderStatus } from "@/types/order";
import { ProductType } from "@/types/product";
import { UserType } from "@/types/user";
import { clsx, type ClassValue } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
const querystring = require("querystring");

export const getQueryParams = (obj: object) => querystring.stringify(obj);

export function urlWithQueryParams(url: string, object: any) {
  if (typeof object !== "object") return "";

  if (!url) return "";

  const params = getQueryParams(object);
  const hasParams = Object.keys(params).length > 0;

  return `${url}${hasParams ? `?${params}` : ``}`;
}

export const getTimeFormat = (
  seconds: number,
  hasHours: boolean = false
): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  if (hasHours)
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
};

export function persianToEnglishNumbers(inputStr: string): string {
  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const translationMap: { [key: string]: string } = {};
  persianNumbers.forEach((persian, index) => {
    translationMap[persian] = englishNumbers[index];
  });

  return inputStr.replace(/[۰-۹]/g, (match) => translationMap[match]);
}
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function breadcrumbItemsGenerator(items: BreadcrumbProps["items"]) {
  return [{ title: "Home", href: "/" }, ...items];
}

export function getFullName(item: UserType) {
  let output = item?.name ?? "";

  if (item?.surename) output += ` ${item.surename}`;

  return output.trim();
}

export function getHumanDate(
  date: string,
  format: string = "YYYY/MM/DD | HH:mm:ss"
) {
  return moment(date).format(format);
}

export function getFullAssets(source: string) {
  return `${VARZ.assetsBaseUrl}/${source}`;
}

export const getPriceFormat = (price: number, unit?: string) =>
  `${price?.toLocaleString()}${unit !== undefined ? ` ${unit}` : ""}`;

export function productPriceFinal(product: ProductType) {
  let productPrice = product?.price;

  if (product?.discountAmount) {
    productPrice = productPrice - product?.discountAmount;
  } else if (product?.discountPercentage) {
    productPrice =
      product.price - (product.price * product.discountPercentage) / 100;
  }

  return productPrice;
}

export function productDiscountFinal(product: ProductType) {
  if (product?.discountAmount) return product.discountAmount;

  if (product?.discountPercentage) {
    return (product.price * product.discountPercentage) / 100;
  }

  return 0;
}

export const getOrderPersianStatus = (status: OrderStatus) => {
  const object: any = {
    [OrderStatus.PENDING]: "در حال بررسی",
    [OrderStatus.PROCESSING]: "در حال آماده سازی",
    [OrderStatus.CANCELED]: "لغو شده",
    [OrderStatus.DELIVERED]: "تحویل داده شده",
    [OrderStatus.ON_THE_WAY]: "در مسیر",
    [OrderStatus.PAID]: "پرداخت شده",
    [OrderStatus.REFUND]: "مرجوعی",
  };
  return object[status];
};

type FormValues = Record<string, any>;

export function getNestedValue(obj: FormValues, path: string): any {
  if (!obj || !path) return undefined;

  // Parse the string path to an array, accounting for `gallery[1]media` format
  const pathParts = path
    .replace(/\[/g, ".") // Replace `[` with `.`
    .replace(/\]/g, "") // Remove `]`
    .replace(/(\d+)([^\.\d])/g, "$1.$2") // Add a dot between a number and next property (e.g., `1]media` -> `1.media`)
    .split("."); // Split into parts

  // Traverse the object to get the nested value
  return pathParts.reduce(
    (acc, part) => (acc && acc[part] !== undefined ? acc[part] : undefined),
    obj
  );
}
