import React, { ReactNode, useState } from "react";
import { MenuItemType } from ".";
import { ChevronDown } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import useAuth from "@/hooks/auth";

interface MenuItemProps extends MenuItemType {}
const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { permissions } = useAuth();

  const { label, children, icon, count, permission, render } = props;

  const path = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  if (permission !== undefined && !permissions?.includes(permission))
    return null;

  let mainContent: ReactNode = (
    <>
      <div
        onClick={children ? toggleExpand : undefined}
        className={`flex rounded-xl p-2 my-2 mx-3 justify-between items-center  hover:bg-slate-50`}
      >
        <div className='flex flex-row items-center gap-x-2'>
          {!!icon && icon}
          <span>{label}</span>
          {!!count && (
            <span className='w-6 h-6 flex items-center justify-center bg-error-600 rounded-full text-foreground'>
              {count}
            </span>
          )}
        </div>
        {children && (
          <span
            className={`transition-transform ${isExpanded ? "rotate-90" : ""}`}
          >
            <ChevronDown size={16} />
          </span>
        )}
      </div>
      {children && isExpanded && (
        <ul className='mr-4 pr-2 border-r border-black/20'>
          {children.map((child, index) => {
            const isSelected = path.pathname === child.href;

            let content: ReactNode = (
              <Link
                to={child?.href ?? ""}
                className='w-full flex gap-x-2 hover:bg-slate-50 items-center p-2 rounded-lg'
              >
                {!!child?.icon && child?.icon}
                {child.label}
              </Link>
            );

            if (typeof child?.render !== "undefined")
              content = (child as any)?.render(child);

            return (
              <li
                key={index}
                className={cn(
                  "pl-2 py-1 rounded",
                  isSelected ? "text-primary" : "text-black/60"
                )}
              >
                {content}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );

  if (typeof render !== "undefined") mainContent = render(props);

  return <li className={cn("cursor-pointer")}>{mainContent}</li>;
};

export default MenuItem;
