export const VARZ = {
  apiBaseUrl: process.env.REACT_APP_PUBLIC_API_URL,
  assetsBaseUrl: process.env.REACT_APP_PUBLIC_ASSETS_URL,
  tinyMCEApi: process.env.REACT_APP_PUBLIC_TINY_MCE,
  perPage: 30,
  priceUnit: process.env.REACT_APP_PUBLIC_UNIT_PRICE ?? "تومان",
  multiLangFields: [
    { title: "EN", value: "en" },
    { title: "FA", value: "fa" },
    { title: "AR", value: "ar" },
  ],
  defaultLang: "en",
};
