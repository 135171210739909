import { ReactNode } from "react";
import MenuItem from "./menu-item";
import {
  Boxes,
  LayoutDashboard,
  List,
  Plus,
  Settings,
  ShieldCheck,
  Truck,
  User,
} from "lucide-react";
import AdminOverview from "./admin-overview";
import LogoutRenderer from "@/components/shared/logout-renderer";
import { OrgButton } from "@/components/shared-ui";
import { WholePermissions } from "@/types/role";

export interface MenuItemType {
  label: string;
  children?: MenuItemType[];
  href?: string;
  permission?: string;
  icon?: ReactNode;
  count?: number;
  render?: (item: MenuItemType) => ReactNode;
}

const ICON_FONT_SIZE = 20;

const menuItems: MenuItemType[] = [
  { icon: <LayoutDashboard />, label: "Dashboard", href: "/dashboard" },
  {
    icon: <List size={ICON_FONT_SIZE} />,
    label: "Posts",
    children: [
      {
        label: "Add",
        href: "/posts/add",
        icon: <Plus size={ICON_FONT_SIZE} />,
      },
      {
        label: "List",
        href: "/posts/list",
        icon: <List size={ICON_FONT_SIZE} />,
      },
    ],
  },
  {
    icon: <List size={ICON_FONT_SIZE} />,
    label: "Comments",
    permission: WholePermissions.GET_COMMENTS,
    children: [
      {
        label: "Add",
        href: "/comments/add",
        icon: <Plus size={ICON_FONT_SIZE} />,
        permission: WholePermissions.CREATE_COMMENTS,
      },
      {
        label: "List",
        href: "/comments/list",
        icon: <List size={ICON_FONT_SIZE} />,
        permission: WholePermissions.GET_COMMENTS,
      },
    ],
  },
  {
    icon: <Boxes size={ICON_FONT_SIZE} />,
    label: "Categories",
    permission: WholePermissions.GET_CATEGORIES,
    children: [
      {
        label: "Add",
        href: "/categories/add",
        icon: <Plus size={ICON_FONT_SIZE} />,
        permission: WholePermissions.CREATE_CATEGORY,
      },
      {
        label: "List",
        href: "/categories/list",
        icon: <List size={ICON_FONT_SIZE} />,
        permission: WholePermissions.GET_CATEGORIES,
      },
    ],
  },
  {
    icon: <ShieldCheck size={ICON_FONT_SIZE} />,
    label: "Roles",
    permission: WholePermissions.GET_ROLES,
    children: [
      {
        label: "Add",
        href: "/roles/add",
        icon: <Plus size={ICON_FONT_SIZE} />,
        permission: WholePermissions.EDIT_ROLE,
      },
      {
        label: "List",
        href: "/roles/list",
        icon: <List size={ICON_FONT_SIZE} />,
        permission: WholePermissions.EDIT_ROLE,
      },
    ],
  },
  {
    icon: <User size={ICON_FONT_SIZE} />,
    label: "Users",
    permission: WholePermissions.GET_USERS,
    children: [
      {
        label: "Add",
        href: "/users/add",
        icon: <Plus size={ICON_FONT_SIZE} />,
      },
      {
        label: "List",
        href: "/users/list",
        icon: <List size={ICON_FONT_SIZE} />,
        permission: WholePermissions.GET_USERS,
      },
    ],
  },
  {
    icon: <Settings size={ICON_FONT_SIZE} />,
    label: "Settings",
    permission: WholePermissions.GET_SETTINGS,
    children: [
      {
        label: "List",
        href: "/settings/list",
        icon: <List size={ICON_FONT_SIZE} />,
        permission: WholePermissions.GET_SETTINGS,
      },
    ],
  },
  {
    label: "Logout",
    render: (item) => (
      <div className='mx-4 mt-4 mb-4'>
        <LogoutRenderer
          renderer={(open) => (
            <OrgButton variant={"outline"} onClick={open}>
              Logout
            </OrgButton>
          )}
        />
      </div>
    ),
  },
];

export default function Sidebar() {
  return (
    <aside className='w-64 border-r h-screen fixed overflow-y-auto bg-background'>
      <AdminOverview />
      <ul className='mt-4 flex flex-col'>
        {menuItems.map((item, key) => (
          <MenuItem {...item} key={key} />
        ))}
      </ul>
    </aside>
  );
}
