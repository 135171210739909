import useAuth from "@/hooks/auth";
import { getFullName } from "@/lib/utils";
import { Headset } from "lucide-react";

export default function AdminOverview() {
  const { user } = useAuth();

  return (
    <div className='border-b border-black/10 sticky top-0 bg-white z-10 py-4 flex flex-col gap-y-2'>
      <div className='px-4 pb-0 text-xl font-bold'>Qureasy Admin Panel</div>
      {!!user && (
        <div className='flex flex-row items-center gap-x-2 px-4'>
          <Headset size={16} />
          <span>{getFullName(user)}</span>
        </div>
      )}
    </div>
  );
}
