import { VARZ } from "@/const/varz";
import store, { resetStoreAction } from "@/store";
import { logout, refreshAccessToken } from "@/store/slices/auth/slice";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { toast } from "sonner";

export type Pagination<T> = {
  items: T[];
  meta: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
  };
};

export type FetchDataType<T> = {
  data: T;
};

export type FetchPaginatedDataType<T> = {
  items: T[];
  _meta: {
    currentPage: number;
    pageCount: number;
    perPage: number;
    totalCount: number;
  };
  _links: {
    first: { href: string };
    last: { href: string };
    self: { href: string };
  };
};

// Create a new Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: VARZ.apiBaseUrl,
  headers: {
    lang: "*",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to refresh access token if needed
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const state = store.getState();
    const refreshToken = state.auth.refreshToken;

    // If the error is due to an expired access token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!refreshToken) {
        store.dispatch(logout());
        return Promise.reject(error);
      }
      try {
        const res = await store.dispatch(refreshAccessToken(refreshToken));

        const newAccessToken = (res.payload as any)?.tokens
          ?.accessToken as string;

        if (newAccessToken) {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (err) {
        //User refresh token wasn't valid
        store.dispatch(resetStoreAction());
        store.dispatch(logout());

        return Promise.reject(err);
      }
    }

    const errorData = error.response.data;

    if (
      errorData?.message &&
      Array.isArray(errorData?.message) &&
      typeof window !== "undefined"
    ) {
      for (let error of errorData.message) {
        toast.error(error);
      }
      return Promise.reject(error);
    }

    if (
      typeof window !== "undefined" &&
      errorData?.["message"] &&
      typeof errorData["message"] === "string"
    ) {
      //Means we have error string as detail
      toast.error(errorData["message"]);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
